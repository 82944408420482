<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['chartData', 'options'],
  watch: {
    chartData () {
      this.$nextTick(() => {
        this.renderChart(this.chartData, this.options);
      })
    },
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  methods: {
    update () {
      this.$data._chart.update()
    },
  },
}
</script>
