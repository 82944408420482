<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartData', 'options'],
  watch: {
    chartData () {
      this.$nextTick(() => {
        this.init()
      })
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.addPlugin({
        id: 'my-plugin',
        beforeDraw: function(chart) {
          const { width, height, ctx, config } = chart.chart
          ctx.restore()
          
          var fontSize1 = (height / 200).toFixed(2)
          ctx.font = fontSize1 + 'em sans-serif'
          ctx.textBaseline = 'middle'

          var text1 = `Total ${config.data.datasets?.[0]?.title}`
          var textX1 = Math.round((width - ctx.measureText(text1).width) / 2)
          var textY1 = height / 2.5

          ctx.fillText(text1, textX1, textY1)

          var sum = 0
          config.data.datasets?.[0]?.data?.forEach(d => sum += d)
          var text2 = new Intl.NumberFormat('id-ID').format(sum)
          var divider = text2.length < 10
            ? 100
            : text2.length > 11
            ? 160
            : 130

          var fontSize2 = (height / divider).toFixed(2)
          ctx.font = fontSize2 + 'em sans-serif'
          ctx.textBaseline = 'middle'

          var textX2 = Math.round((width - ctx.measureText(text2).width) / 2)
          var textY2 = height / 1.75

          ctx.fillText(text2, textX2, textY2)
          ctx.save()
        },
      })
      this.renderChart(this.chartData, this.options)
    },
    update () {
      this.$data._chart.update()
    },
  },
}
</script>
