<template>
  <div>
    <h3 v-if="summaryName === 'is_today'" class="mb-2">
      {{ $t('dashboardContent.today_summary') }}
    </h3>
    <a-row :gutter="16">
      <a-col v-for="summary in summaryList" :key="summary.id" :span="8">
        <a-card class="pbz-card" :bordered="false" :style="{ borderBottom: `5px solid ${color[summary.name]}` }">
          <div class="pbz-card-icon">
            <a-icon :type="summary.icon" />
          </div>
          <p class="mb-3 fw-500">
            {{ $t(`dashboardContent.${summary.name}`) }}
            <span
              v-if="summaryName === 'is_today' && !loading"
              :style="{
                marginLeft: '15px',
                fontSize: '12px',
                color: +summary.percent < 0
                  ? '#f5222d'
                  : '#52c41a'
              }"
            >
              <a-icon v-if="+summary.percent > 0" type="arrow-up" />
              <a-icon v-if="+summary.percent < 0" type="arrow-down" />
              {{ formatNumber(summary.percent.toFixed(2).toString().replace('-', '')) }}%
            </span>
          </p>
          <a-row :gutter="16">
            <a-col :span="summary.name === 'sales' ? 10 : 24">
              <div class="pbz-card-title">
                <p class="mb-1">
                  {{ $t(`dashboardContent.total.${summary.name}`) }}
                  <a-tooltip v-if="summary.name === 'sales'" placement="top">
                    <template slot="title">
                      <span>{{ `${summary.prefix} ${formatNumber(summary.value)}` }}</span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
              </div>
              <div class="pbz-card-content">
                <a-skeleton :loading="loading" :paragraph="{ rows: 0 }" active>
                  <a-tooltip v-if="summary.name === 'sales'" placement="top">
                    <template slot="title">
                      <span>{{ `${summary.prefix} ${formatNumber(summary.value)}` }}</span>
                    </template>
                    {{ `${summary.prefix} ${convertNumber(summary.value)}` }}
                  </a-tooltip>
                  <template v-else>
                    {{ `${summary.prefix} ${formatNumber(summary.value)} ${summary.suffix}` }}
                  </template>
                </a-skeleton>
              </div>
            </a-col>
            <a-col v-if="summary.name === 'sales'" :span="10">
              <div class="pbz-card-title">
                <p class="mb-1">
                  {{ $t(`dashboardContent.total.canceled_${summary.name}`) }}
                  <a-tooltip v-if="summary.name === 'sales'" placement="top">
                    <template slot="title">
                      <span>{{ `${summary.prefix} ${formatNumber(summary.canceled)}` }}</span>
                    </template>
                    <a-icon type="info-circle" />
                  </a-tooltip>
                </p>
              </div>
              <div class="pbz-card-content" style="color: #f5222d">
                <a-skeleton :loading="loading" :paragraph="{ rows: 0 }" active>
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ `${summary.prefix} ${formatNumber(summary.canceled)}` }}</span>
                    </template>
                    {{ `${summary.prefix} ${convertNumber(summary.canceled)}` }}
                  </a-tooltip>
                </a-skeleton>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'DashboardSummary',
  props: {
    summaryName: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    summaryList: {
      type: Array,
      default: () => [],
    },
    formatNumber: {
      type: Function,
      default: () => {},
    },
    convertNumber: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      color: {
        sales: '#56f27c',
        logistic_expenses: '#f256cc',
        item_sold: '#f27c56',
      },
    }
  },
}
</script>